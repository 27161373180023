import styled from 'styled-components'
import { grayscale, orange } from 'src/styles/colors'

export const Section = styled.section`
  a {
    color: ${grayscale[500]};

    &:hover {
      color: ${orange.extra}
    }
  }
`
