export default [
  {
    image: 'https://central-imagens.bancointer.com.br/images-without-small-versions/blog1-consumidor/image.webp',
    altText: 'celular, tênis branco e console posicionados lado a lado',
    title: 'Tudo sobre o dia do Consumidor e o que vale a pena comprar',
    link: 'https://blog.inter.co/dia-do-consumidor/',
  },
  {
    image: 'https://central-imagens.bancointer.com.br/images-without-small-versions/blog2-consumidor/image.webp',
    altText: 'ícone de celular com interrogação no centro da tela',
    title: 'Melhores celulares em 2024: saiba qual escolher',
    link: 'https://blog.inter.co/melhores-celulares/',
  },
  {
    image: 'https://central-imagens.bancointer.com.br/images-without-small-versions/blog3-consumidor/image.webp',
    altText: 'três air fryers posicionadas lado a lado',
    title: 'Qual a melhor air fryer pra comprar em 2024? Confira o top 10',
    link: 'https://blog.inter.co/melhor-air-fryer/',
  },
  {
    image: 'https://central-imagens.bancointer.com.br/images-without-small-versions/blog4-consumidor/image.webp',
    altText: 'ilustrações de sacolas de compras',
    title: 'Guia de Compras com os melhores produtos do mercado',
    link: 'https://blog.inter.co/guia-de-compras/',
  },
]
