import styled from 'styled-components'
import { breakpoints } from 'src/styles/breakpoints'

export const Card = styled.a`
  img {
    border-radius: 5px;
  }

  h3 {
    font-size: 17px;
    line-height: 20px;

    @media (min-width: ${breakpoints.md}) {
      font-size: 10px;
      line-height: 12px;
    }

    @media (min-width: ${breakpoints.lg}) {
      font-size: 13px;
      line-height: 15px;
    }

    @media (min-width: ${breakpoints.xl}) {
      font-size: 16px;
      line-height: 19px;
    }
  }

  .card-image {
    width: 100%;
    object-fit: cover;
  }
`
