import React, { useState } from 'react'
import Layout from 'src/layouts/BaseLayout'

import { Wrapper } from './style'
import { Modal } from 'src/components/Modal'
import { QrCodeModal } from 'src/components/Modal/QrCodeModal'

import pageContext from './pageContext.json'
import Hero from './_sections/_hero/_index'
import ConsumerMonthHotDeals from './_sections/consumer-month-hot-deals/_index'
import ToSaveMoney from './_sections/to-save-money/_index'
import Conditions from './_sections/conditions/_index'
import DayToDay from './_sections/day-to-day/_index'
import DontILeaveItforTomorrow from './_sections/dont-leave-it-for-tomorrow/_index'
import Save from './_sections/save/_index'
import MostDesired from './_sections/most-desired/_index'
import Blog from './_sections/blog/_index'
import ConsumerDay from './_sections/consumer-day/_index'
import Faq from './_sections/faq/_index'
import useDomReady from 'src/hooks/window/useDomReady'
import QrCodeMeuPorquinho from './assets/image/qr-code-semana-do-consumidor.png'

const ConsumerMonth = () => {
  const [ isOpen, setIsOpen ] = useState(false)
  const domReady = useDomReady()

  const openModal = domReady && (
    <Modal isModalOpen={isOpen} setIsModalOpen={setIsOpen} locationToRender={document.body}>
      <QrCodeModal
        isModal={isOpen}
        setIsModal={setIsOpen}
        title='Baixe agora o <span class="text-orange--extra">Super App</span> e abra sua conta gratuita'
        instructions='Ligue a câmera do seu celular e aponte para o QR Code acima. Você será redirecionado para a loja de aplicativos.'
        qrCode={QrCodeMeuPorquinho}
      />
    </Modal>
  )

  return (
    <Wrapper>
      {openModal}
      <Layout pageContext={pageContext}>
        <Hero setOpenModal={setIsOpen} />
        <ConsumerMonthHotDeals />
        <ToSaveMoney />
        <Conditions setOpenModal={setIsOpen} />
        <DayToDay />
        <DontILeaveItforTomorrow />
        <Save setOpenModal={setIsOpen} />
        <MostDesired />
        <Blog />
        <ConsumerDay />
        <Faq />
      </Layout>
    </Wrapper>
  )
}

export default ConsumerMonth
