import { device } from 'src/styles/breakpoints'
import styled from 'styled-components'
import Show from '../../assets/image/show.png'
import { orange, grayscale } from 'src/styles/colors'

export const Section = styled.section`
  position: relative;

  .react-multi-carousel-dot--active {
    button {
      background: ${orange.base};
    }
  }
  .react-multi-carousel-dot {
    button {
      background: ${orange.base};
    }
  }
  .react-multi-carousel-track {
    padding: 30px 0 20px;
  }
`

export const Card = styled.div`
  background: #fff;
  width: 100%;
  height: 443px;
  border-radius: 12px;
  box-shadow: 0px 8px 16px 2px rgba(22, 22, 22, 0.08);
  overflow: hidden;

  @media ${device.tablet} {
    height: 366px;
  }

  @media ${device.desktopLG} {
    height: 374px;
  }

  @media ${device.desktopXL} {
    height: 366px;
  }

  @media ${device.desktopXXXL} {
    height: 486px;
  }
  
  img {
    width: auto;
    margin: 0 auto;
    height: 55%;

    @media ${device.desktopXXXL} {
      width: auto;
      margin: 0 auto;
      height: 90%;
    }
  }

`
export const Button = styled.a`
  background: #ff7a00;
  border-radius: 8px;
  border: none;
  width: 90%;
  height: 48px ;
  color: #fff;
  font-size:14px;
  line-height: 17px;
  font-weight: 600 ;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  padding: 10px 0 10px 0;

  @media ${device.tablet} {
    width: 85%;
  }

  &:focus, &:hover {
    outline: none;
    text-decoration: none;
    color: #fff;
  }
`
export const Header = styled.div`
  width: 100%;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #161616;
  border-radius: 10px 10px 0 0;
`

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  
  @media ${device.tablet} {
    padding: 20px 0px 0px;
    display: flex;
    flex-direction: column;
    -webkit-box-pack: justify;
    justify-content: space-between;
    height: 270px;
  }

  .card-img {
    position: relative;
    top: -10px;
  }

  .product {
    width: 90%;
    margin: 0 auto;

    img {
      width: 100%;
      margin: 0 auto;
      top: 5px;
      position: relative;
    }
  }

  .content {
    @media ${device.desktopXL} {
      width: 92%;
      bottom: 20px;
      position: absolute;
    }
  }

  @media ${device.desktopXL} {
    padding: 16px 24px 24px;
  }

  @media ${device.desktopLG} {
    padding: 0;
  }
`
export const View = styled.div`
  border-radius: 20px;
  height: 40px;
  width: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #DEDFE4;
  background-image: url(${Show});
  background-repeat: no-repeat;
  background-position: 10px 14px;

  svg {
    margin-left: 8px;
  }

`

export const Circle = styled.div`
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  font-size: 14px;
  line-height: 17px;
  font-weight: 600;
  color: ${grayscale[500]};
`
