import styled from 'styled-components'
import { primary } from 'src/styles/newColors'
import { device } from 'src/styles/breakpoints'
import { orange } from 'src/styles/colors'

export const Section = styled.section`
  padding-top: 40px;
  
  .img {
    @media ${device.tablet} {
      width: 211px;
    }

    @media ${device.desktopXL} {
      width: 177px;
    }
  }
`

export const Card = styled.div`
  width: 229px;
  height: 106px;
  background-color: ${primary[500]};
  border-radius: 40px 20px 40px 20px;
  position: relative;

  .headerCard {
    top: -23px;
    left: -5px;
    position: absolute;
  }

  .title {
    border-radius: 20px;
    font-family: 'Citrina';
    width: auto;
  }

  .titleLiveShop,
  .titlePagamento,
  .titleCupons,
  .titleAlerta {
    @media ${device.tablet} {
      letter-spacing: -1px;
    }
  }

  .titleLiveShop {
    background-color: #F8B6E6;
  }

  .titlePagamento {
    background-color: #66CCFB;
  }

  .titleCupons {
    background-color: #FBFF71;
  }

  .titleAlerta {
    background-color: #80E7BD;
  }

  p {
    font-family: 'Citrina';
  }
`

export const Button = styled.div`
  border: 1px solid #FFFFFF;
  background-color: ${orange.extra};

  &:focus, &:hover {
    outline: none;
    text-decoration: none;
    color: #fff;
  }

  float: left;
  clear: both;

  @media ${device.tablet} {
    width: 336px;
  }

  @media ${device.desktopLG} {
    width: 398px;
  }
`