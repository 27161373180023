import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

export const Section = styled.section`
  height: 610px;
  background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/360-dobra4/image.webp');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 0 280px;

  @media ${device.tablet} {
    height: 392px;
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/768-dobra3-faixa/image.webp');
    background-position: top left;
  }

  @media ${device.desktopLG} {
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/768-dobra3-faixa/image.webp');
    height: 524px;
    background-size: contain;
  }

  @media ${device.desktopXL} {
    height: 496px;
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/1440-dobra3-faixa/image.webp');

  }

  a {
    border: 1px solid #FFFFFF;

  &:focus, &:hover {
    outline: none;
    text-decoration: none;
    color: #fff;
  }

  @media ${device.tablet} {
    width: 337px;
  }

  @media ${device.desktopLG} {
    width: 351px;
  }

  @media ${device.desktopXL} {
    width: 457px;
  }
`