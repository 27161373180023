import React from 'react'
import ImageWebp from 'src/components/ImageWebp'

// Hooks
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

const DontILeaveItforTomorrow = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <section className='py-5'>
      <div className='container py-lg-5'>
        <div className='row'>
          <div className='col-12 col-md-6 col-lg-5 offset-lg-2 d-flex justify-content-center order-md-last'>
            <ImageWebp
              pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/360-consumidor-dobra6/image.webp'
              altDescription='Mulher sorridente usando chapéu saindo do carro'
              arrayNumbers={[ 285, 335, 387, 387 ]}
            />
          </div>
          <div className='col-12 col-md-6 col-lg-5 mt-3'>
            <h2 className='fs-24 fs-lg-40 fs-md-28 fs-xl-48 lh-28 lh-md-34 lh-lg-48 lh-xl-58 text-grayscale--500 mb-4 fw-500'>Não deixe pra amanhã o sonho que você pode conquistar hoje</h2>
            <ul className='ml-3'>
              <li className='text-graphite fs-14 fs-md-16 fs-lg-18 lh-17 lh-md-20 lh-lg-22'>Carro e moto até R$ 50 mil com parcelas iniciais a R$10.</li>
              <li className='text-graphite fs-14 fs-md-16 fs-lg-18 lh-17 lh-md-20 lh-lg-22'>Veículo acima de R$ 50 mil e imóvel com parcelas reduzidas em 60% até a conquista do bem.</li>
            </ul>
            <a
              href='https://intergo.app/0fc1ccfe'
              target='blank'
              className='btn btn--lg bg-primary--500 rounded-2 fs-14 fw-600 text-none text-white mt-3 mb-0 mw-100'
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_06',
                  section_name: 'Não deixe pra amanhã o sonho que você pode conquistar hoje',
                  element_name: 'Aproveitar agora',
                  element_action: 'click button',
                  redirect_url: 'https://intergo.app/0fc1ccfe',
                })
            } }
            >
              Aproveitar agora
            </a>
          </div>
        </div>
      </div>  
    </section>
  )
}

export default DontILeaveItforTomorrow
