import React from 'react'

// Hooks
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

// Components
import ImageWebp from 'src/components/ImageWebp'

// Data
import blogJSON from '../../assets/data/_blog'

import { Card } from './style'

// types
type blogProps = {
  image: string;
  title: string;
  link: string;
  altText: string;
}

const Blog = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <section className='py-5 bg-yellow--extra-light'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <h2
              className='fs-24 lh-30 fs-md-32 lh-md-40 fs-xl-40 lh-xl-50 text-grayscale--500 fw-600 mb-5 text-center'
            >
              Dicas pra aproveitar a Semana do Consumidor 2024
            </h2>
          </div>
          {
            blogJSON.map((item: blogProps) => (
              <div className='col-12 col-md-3 mb-2' key={item.title}>
                <Card
                  href={item.link}
                  target='_blank'
                  rel='noreferrer'
                  onClick={() => {
                    sendDatalayerEvent({
                      section: 'dobra_7',
                      section_name: 'Dicas pra aproveitar a Semana do Consumidor 2024',
                      element_action: 'click button',
                      element_name: item.title,
                      redirect_url: item.link,
                    })
                  }}
                >
                  <ImageWebp
                    className='card-image'
                    pathSrc={item.image}
                    altDescription={item.altText}
                    arrayNumbers={[ 302, 164, 219, 264 ]}
                    arrayNumbersHeight={[ 161, 94, 126, 152 ]}
                  />
                  <h3 className='text-grayscale--500 fw-600 mt-2 mt-lg-3'>{item.title}</h3>
                </Card>
              </div>
            ))
          }
        </div>
      </div>
    </section>
  )
}

export default Blog
