import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import { primary } from 'src/styles/newColors'

export const Section = styled.section`
  background-color: ${primary[500]};
  min-height: 528px;

  .gift-card {
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/mobile-grafismo/image.webp');
    background-size: contain;
    background-repeat: no-repeat;
    padding: 25px 170px 0 52px;
    height: 144px;

    @media ${device.tablet} {
      background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/produto1-giftcard/image.webp');
      background-size: contain;
      background-repeat: no-repeat;
      height: 239px;
      padding: 15px 30px 0 32px;
    }

    @media ${device.desktopLG} {
      height: 311px;
      background-size: cover;
      padding: 25px 0 0 32px;
    }

    @media ${device.desktopXL} {
      height: 371px;
      background-size: cover;
      padding: 25px 11px 0 32px;
    }

    @media ${device.desktopXXXL} {
      height: 461px;
    }
  }

  .recarga {
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/mobile-grafismo2/image.webp');
    background-size: contain;
    background-repeat: no-repeat;
    padding: 25px 170px 0 52px;
    height: 144px;

    @media ${device.tablet} {
      background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/grafismo-mulher-app/image.webp');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: top center;
      height: 118px;
      padding: 15px 112px 0 32px;
    }

    @media ${device.desktopLG} {
      height: 152px;
      background-size: contain;
      padding: 25px 150px 0 50px;
    }
    
    @media ${device.desktopXL} {
      height: 180px;
      background-size: cover;
      padding: 25px 174px 0 50px;
    }

    @media ${device.desktopXXXL} {
      height: 227px;
    }
  }
  .duo-gourmet {
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/mobile-grafismo6/image.webp');
    background-size: contain;
    background-repeat: no-repeat;
    padding: 25px 155px 0 52px;
    height: 144px;

    @media ${device.tablet} {
      background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/grafismo-food/image.webp');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: top center;
      height: 118px;
      padding: 15px 121px 0 32px;
    }

    @media ${device.desktopLG} {
      height: 152px;
      background-size: contain;
      padding: 25px 151px 0 50px;
    }

    @media ${device.desktopXL} {
      height: 180px;
      background-size: cover;
      padding: 25px 183px 0 50px;
    }

    @media ${device.desktopXXXL} {
      height: 227px;
    }
  }
  .plano-celular {
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/mobile-grafismo3/image.webp');
    background-size: contain;
    background-repeat: no-repeat;
    padding: 15px 160px 0 52px;
    height: 144px;

    @media ${device.tablet} {
      background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/grafismo-app/image.webp');
      background-size: 180px;
      background-repeat: no-repeat;
      height: 239px;
      padding: 15px 0 0 32px;
    }

    @media ${device.desktopLG} {
      height: 311px;
      background-size: contain;
      padding: 25px 0 0 32px;
    }

    @media ${device.desktopXL} {
      height: 371px;
      background-size: cover;
      padding: 25px 0 0 32px;
    }

    @media ${device.desktopXXXL} {
      height: 461px;
    }
  }

  .viagens {
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/mobile-grafismo4/image.webp');
    background-size: contain;
    background-repeat: no-repeat;
    padding: 25px 170px 0 52px;
    height: 144px;

    @media ${device.tablet} {
      background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/grafismo-viagem/image.webp');
      background-size: 320px;
      background-repeat: no-repeat;
      height: 129px;
      padding: 25px 142px 0 45px;
    }

    @media ${device.desktopLG} {
      background-size: contain;
      height: 179px;
      padding: 35px 185px 0 55px;
    }

    @media ${device.desktopXL} {
      background-size: cover;
      height: 203px;
      padding: 35px 229px 0 65px;
    }

    @media ${device.desktopXXXL} {
      background-size: contain;
      height: 249px;
      margin-left: 15px;
    }
  }

  .shell-box {
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/mobile-grafismo5/image.webp');
    background-size: contain;
    background-repeat: no-repeat;
    padding: 25px 160px 0 52px;
    height: 144px;

    @media ${device.tablet} {
      background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/shell-grafismo/image.webp');
      background-size: 320px;
      background-repeat: no-repeat;
      height: 129px;
      padding: 25px 142px 0 45px;
    }

    @media ${device.desktopLG} {
      background-size: contain;
      height: 179px;
      padding: 35px 199px 0 55px;
    }

    @media ${device.desktopXL} {
      background-size: cover;
      height: 203px;
      padding: 35px 229px 0 65px;
    }

    @media ${device.desktopXXXL} {
      background-size: contain;
      height: 249px;
      margin-left: 15px;
    }
  }
`
