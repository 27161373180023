import React from 'react'
import { WIDTH_MD } from 'src/utils/breakpoints'

// Hooks
import useWidth from 'src/hooks/window/useWidth'
import { Section } from './style'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

const DayToDay = () => {
  const width = useWidth()
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <Section className='py-5'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <h2 className='fs-24 fs-lg-40 lh-28 lh-lg-48 text-white text-center mb-4'>Pra economizar no seu dia a dia</h2>
          </div>
        </div>
        {width < WIDTH_MD &&
          <div className='row'>
            <>
              <a
                href='https://intergo.app/92b8e0b6'
                target='blank'
                onClick={() => {
                    sendDatalayerEvent({
                      section: 'dobra_4',
                      section_name: 'Pra economizar no seu dia a dia',
                      element_action: 'click button',
                      element_name: 'Gift Card',
                      redirect_url: 'https://intergo.app/92b8e0b6',
                    })
                }}
              >
                <div className='col-12 gift-card mb-4' role='img' aria-label='gift card ifood, uber, google play, xbox, apple e amazon.'>
                  <h3 className='fs-18 lh-22 text-grayscale--500 mb-2'>Gift Card</h3>
                  <p className='fs-10 lh-12 text-grayscale--500'>Economize ganhando cashback em delivery, uber, jogos e mais!</p>
                </div>
              </a>
              <a
                href='https://intergo.app/98dc57d4'
                target='blank'
                onClick={() => {
                    sendDatalayerEvent({
                      section: 'dobra_4',
                      section_name: 'Pra economizar no seu dia a dia',
                      element_action: 'click button',
                      element_name: 'Recarga',
                      redirect_url: 'https://intergo.app/98dc57d4',
                    })
                }}
              >
                <div className='col-12 recarga mb-4' role='img' aria-label='mulher sorridente olhando pro alto e sorrindo enquanto fala ao celular'>
                  <h3 className='fs-18 lh-22 text-grayscale--500 mb-2'>Recarga</h3>
                  <p className='fs-10 lh-12 text-grayscale--500'>Faça recarga de celular ou de TV com vantagens, como GBs de bônus e cashback.</p>
                </div>
              </a>
              <a
                href='https://intergo.app/af594d37'
                target='blank'
                onClick={() => {
                    sendDatalayerEvent({
                      section: 'dobra_4',
                      section_name: 'Pra economizar no seu dia a dia',
                      element_action: 'click button',
                      element_name: 'Plano de Celular',
                      redirect_url: 'https://intergo.app/af594d37',
                    })
                }}
              >
                <div className='col-12 plano-celular mb-4' role='img' aria-label='tela de celular mostrando o plano de celular do inter'>
                  <h3 className='fs-18 lh-22 text-grayscale--500 mb-2'>Plano de Celular </h3>
                  <p className='fs-10 lh-12 text-grayscale--500'>Faça portabilidade com planos a partir de R$15, cobertura Vivo e sem fidelidade.</p>
                </div>
              </a>
              <a
                href='https://intergo.app/df4065dd'
                target='blank'
                onClick={() => {
                    sendDatalayerEvent({
                      section: 'dobra_4',
                      section_name: 'Pra economizar no seu dia a dia',
                      element_action: 'click button',
                      element_name: 'Viagens',
                      redirect_url: 'https://intergo.app/df4065dd',
                    })
                }}
              >
                <div className='col-12 viagens mb-4' role='img' aria-label='avião'>
                  <h3 className='fs-18 lh-22 text-grayscale--500 mb-2'>Viagens</h3>
                  <p className='fs-10 lh-12 text-grayscale--500'>Passagens, hospedagens e aluguel de carros com cashback turbinado.</p>
                </div>
              </a>
              <a
                href='https://intergo.app/004f4aed'
                target='blank'
                onClick={() => {
                    sendDatalayerEvent({
                      section: 'dobra_4',
                      section_name: 'Pra economizar no seu dia a dia',
                      element_action: 'click button',
                      element_name: 'Shell Box',
                      redirect_url: 'https://intergo.app/004f4aed',
                    })
                }}
              >
                <div className='col-12 shell-box mb-4' role='img' aria-label='marca Shell Box em formato de concha'>
                  <h3 className='fs-18 lh-22 text-grayscale--500 mb-2'>Shell Box</h3>
                  <p className='fs-10 lh-12 text-grayscale--500'>Abasteça em postos Shell e ganhe dinheiro de volta na hora, direto na sua conta.</p>
                </div>
              </a>
              <a
                href='https://intergo.app/3ebf0fd5'
                target='blank'
                onClick={() => {
                    sendDatalayerEvent({
                      section: 'dobra_4',
                      section_name: 'Pra economizar no seu dia a dia',
                      element_action: 'click button',
                      element_name: 'Duo Gourmet',
                      redirect_url: 'https://intergo.app/3ebf0fd5',
                    })
                }}
              >
                <div className='col-12 duo-gourmet' role='img' aria-label='hambúrguer com batatas fritas voando em volta e um cartão black ao lado'>
                  <h3 className='fs-18 lh-22 text-grayscale--500 mb-2'>Duo Gourmet</h3>
                  <p className='fs-10 lh-12 text-grayscale--500'>Peça 2 pratos e pague só 1 nos melhores restaurantes e ainda ganhe um cartão Black.</p>
                </div>
              </a>
            </>
          </div>
          }
        {width >= WIDTH_MD &&
          <>
            <div className='row justify-content-center mb-lg-2'>
              <div className='col-md-3 px-0'>
                <a
                  href='https://intergo.app/92b8e0b6'
                  title='Gift Card'
                  target='blank'
                  onClick={() => {
                    sendDatalayerEvent({
                      section: 'dobra_4',
                      section_name: 'Pra economizar no seu dia a dia',
                      element_action: 'click button',
                      element_name: 'Gift Card',
                      redirect_url: 'https://intergo.app/92b8e0b6',
                    })
                  }}
                >
                  <div className='gift-card' role='img' aria-label='gift card ifood, uber, google play, xbox, apple e amazon.'>
                    <h3 className='fs-md-18 fs-lg-24 fs-xl-34 lh-md-21 lh-lg-28 lh-xl-40 text-grayscale--500 mb-md-2'>Gift Card</h3>
                    <p className='fs-md-10 fs-lg-14 fs-xl-18 lh-md-12 lh-lg-16 lh-xl-22 text-grayscale--500'>Economize ganhando cashback em delivery, uber, jogos e mais!</p>
                  </div>
                </a>
              </div>
              <div className='col-md-5 flex-column'>
                <a
                  href='https://intergo.app/98dc57d4'
                  title='Recarga'
                  target='blank'
                  onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_4',
                    section_name: 'Pra economizar no seu dia a dia',
                    element_action: 'click button',
                    element_name: 'Recarga',
                    redirect_url: 'https://intergo.app/98dc57d4',
                  })
                  }}
                >
                  <div className='recarga mb-md-1 mb-lg-2' role='img' aria-label='mulher sorridente olhando pro alto e sorrindo enquanto fala ao celular'>
                    <h3 className='fs-md-18 fs-lg-24 fs-xl-34 lh-md-21 lh-lg-28 lh-xl-40 text-grayscale--500 mb-md-2'>Recarga</h3>
                    <p className='fs-md-10 fs-lg-14 fs-xl-18 lh-md-12 lh-lg-16 lh-xl-22 text-grayscale--500'>Faça recarga de celular ou de TV com vantagens, como GBs de bônus e cashback.</p>
                  </div>
                </a>
                <a
                  href='https://intergo.app/3ebf0fd5'
                  title='Duo Gourmet'
                  target='blank'
                  onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_4',
                    section_name: 'Pra economizar no seu dia a dia',
                    element_action: 'click button',
                    element_name: 'Duo Gourmet',
                    redirect_url: 'https://intergo.app/3ebf0fd5',
                  })
                  }}
                >
                  <div className='duo-gourmet' role='img' aria-label='hambúrguer com batatas fritas voando em volta e um cartão black ao lado'>
                    <h3 className='fs-md-18 fs-lg-24 fs-xl-34 lh-md-21 lh-lg-28 lh-xl-40 text-grayscale--500 mb-md-2'>Duo Gourmet</h3>
                    <p className='fs-md-10 fs-lg-14 fs-xl-18 lh-md-12 lh-lg-16 lh-xl-22 text-grayscale--500'>Peça 2 pratos e pague só 1 nos melhores restaurantes e ainda ganhe um cartão Black.</p>
                  </div>
                </a>
              </div>
              <div className='col-md-3 px-0'>
                <a
                  href='https://intergo.app/af594d37'
                  title='Plano de Celular'
                  target='blank'
                  onClick={() => {
                    sendDatalayerEvent({
                      section: 'dobra_4',
                      section_name: 'Pra economizar no seu dia a dia',
                      element_action: 'click button',
                      element_name: 'Plano de Celular',
                      redirect_url: 'https://intergo.app/af594d37',
                    })
                  }}
                >
                  <div className='plano-celular' role='img' aria-label='tela de celular mostrando o plano de celular do inter'>
                    <h3 className='fs-md-18 fs-lg-24 fs-xl-34 lh-md-21 lh-lg-28 lh-xl-40 text-grayscale--500 mb-md-2'>Plano de Celular </h3>
                    <p className='fs-md-10 fs-lg-14 fs-xl-18 lh-md-12 lh-lg-16 lh-xl-22 text-grayscale--500'>Faça portabilidade com planos a partir de R$15, cobertura Vivo e sem fidelidade.</p>
                  </div>
                </a>
              </div>
            </div>
            <div className='row mt-md-1 mt-lg-2 mt-xl-3 px-md-3 px-xl-4'>
              <div className='col-md-6 pl-lg-4'>
                <a
                  href='https://intergo.app/df4065dd'
                  title='Viagens'
                  target='blank'
                  onClick={() => {
                    sendDatalayerEvent({
                      section: 'dobra_4',
                      section_name: 'Pra economizar no seu dia a dia',
                      element_action: 'click button',
                      element_name: 'Viagens',
                      redirect_url: 'https://intergo.app/df4065dd',
                    })
                  }}
                >
                  <div className='viagens' role='img' aria-label='avião'>
                    <h3 className='fs-md-18 fs-lg-24 fs-xl-34 lh-md-21 lh-lg-28 lh-xl-40 text-grayscale--500 mb-md-2'>Viagens</h3>
                    <p className='fs-md-10 fs-lg-14 fs-xl-18 lh-md-12 lh-lg-16 lh-xl-22 text-grayscale--500'>Passagens, hospedagens e aluguel de carros com cashback turbinado.</p>
                  </div>
                </a>
              </div>
              <div className='col-md-6 pr-lg-4'>
                <a
                  href='https://intergo.app/004f4aed'
                  title='Shell Box'
                  target='blank'
                  onClick={() => {
                    sendDatalayerEvent({
                      section: 'dobra_4',
                      section_name: 'Pra economizar no seu dia a dia',
                      element_action: 'click button',
                      element_name: 'Shell Box',
                      redirect_url: 'https://intergo.app/004f4aed',
                    })
                  }}
                >
                  <div className='shell-box' role='img' aria-label='marca Shell Box em formato de concha'>
                    <h3 className='fs-md-18 fs-lg-24 fs-xl-34 lh-md-21 lh-lg-28 lh-xl-40 text-grayscale--500 mb-md-2'>Shell Box</h3>
                    <p className='fs-md-10 fs-lg-14 fs-xl-18 lh-md-12 lh-lg-16 lh-xl-22 text-grayscale--500'>Abasteça em postos Shell e ganhe dinheiro de volta na hora, direto na sua conta.</p>
                  </div>
                </a>
              </div>
            </div>
          </>
          }
      </div>
    </Section>
  )
}

export default DayToDay
