import React from 'react'

// Hooks
import useWidth from 'src/hooks/window/useWidth'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import { Section } from './style'

// Data
import MostDesiredJSON from '../../assets/data/mostDesired.json'

type MostDesiredProps = {
  text: string;
  url: string;
}

const MostDesired = () => {
  const width = useWidth()
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <Section className='py-5'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <h2 className='fs-24 fs-lg-40 fs-xl-48 lh-28 lh-lg-48 lh-xl-48 text-grayscale--500 fw-700 text-center'><span className='d-block d-md-inline'>Produtos mais</span> desejados</h2>
            <div className='mt-4 text-center'>
              {
                MostDesiredJSON.map((item: MostDesiredProps) => (
                  <a 
                    key={item.text}
                    href={item.url}
                    title={item.text}
                    className='fs-14 mr-3'
                    onClick={() => {
                      sendDatalayerEvent({
                        section: 'dobra_6',
                        section_name: 'Produtos mais desejados',
                        element_action: 'click button',
                        element_name: item.text,
                        redirect_url: item.url,
                      })
                    }}
                  >{item.text}
                  </a>
                ))
              }
            </div>
          </div>
        </div>
      </div>  
    </Section>
  )
}

export default MostDesired
