import React from 'react'

// Hooks
import useWidth from 'src/hooks/window/useWidth'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

// Components
import ImageWebp from 'src/components/ImageWebp'
import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'

import { WIDTH_MD } from 'src/utils/breakpoints'

import cardInter from '../../assets/data/_card'
// import cardInterMobile from '../../assets/data/_card-mobile'

import { Card, Button, Header, Body, View, Circle, Section } from './style'

type CardProps = {
  title: string;
  image: string;
  text: string;
  link: string;
  paragraph?: string;
  name: string;

}

const radomViews = () => {
  return Math.floor(Math.random() * 2999) + 50
}

const ConsumerMonthHotDeals = () => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const width = useWidth()

  return (
    <Section className='bg-yellow--extra-light py-5'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 text-grayscale--500 fw-600 text-md-center'>
              Camarão que dorme, a onda leva...
            </h2>
            <p className='fs-14 fs-md-18 lh-17 lh-md-22 fw-400 text-md-center'>
              ...então não perca as ofertas relâmpago!
            </p>
          </div>
          {width < WIDTH_MD ? <div className='col-12'>
            <DefaultCarousel
              sm={{ items: 1, partialVisibilityGutter: 110 }}
              md={{ items: 3 }}
              lg={{ items: 4 }}
              xl={{ items: 4 }}
              removeArrowOnDeviceType={[ 'mobile' ]}
              notShowDots={true}
              itemClass='px-1'
              >
              {
                cardInter.slice(0, 8).map((item: CardProps) => (
                  <Card className='d-flex align-content-stretch flex-wrap' key={item.text}>
                    <Header>
                      <p className='fs-14 lh-17 fs-md-16 lg-md-19 fs-xl-18 lh-xl-22 text-white mb-0'>{item.title}</p>
                    </Header>
                    <Body>
                      <div className='product'>
                        <div className='card-img'>
                          <ImageWebp
                            pathSrc={item.image}
                            altDescription={item.paragraph}
                            arrayNumbers={[ 210, 154, 219, 264 ]}
                            arrayNumbersHeight={[ 140, 94, 126, 152 ]}
                          />
                        </div>
                        <div className='row d-flex align-items-center mt-4'>
                          <div className='col-6 col-xl-5 d-flex justify-content-center mb-xl-0 mb-3'>
                            <View className='d-flex justify-content-end'>
                              <Circle>
                                {radomViews()}
                              </Circle>
                            </View>
                          </div>
                          <div className='col-6 col-xl-7 pl-xl-0'>
                            <p className='fs-10 lh-15 text-black'>{item.text}</p>
                          </div>
                        </div>
                        <Button
                          href={item.link}
                          className='fw-700'
                          onClick={() => {
                            sendDatalayerEvent({
                              section: 'dobra_2',
                              section_name: 'Esquenta Semana do Consumidor',
                              element_action: 'click button',
                              element_name: item.name,
                              redirect_url: item.link,
                            })
                          }}
                          target='__blank'
                        >
                          Abrir ofertas
                        </Button>
                      </div>
                    </Body>
                  </Card>
                ))
              }
            </DefaultCarousel>
            <DefaultCarousel
               sm={{ items: 1, partialVisibilityGutter: 110 }}
               md={{ items: 3 }}
               lg={{ items: 4 }}
               xl={{ items: 4 }}
               removeArrowOnDeviceType={[ 'mobile' ]}
               notShowDots={true}
               itemClass='px-1'
            >
              {
                cardInter.slice(8, 12).map((item: CardProps) => (
                  <Card className='d-flex align-content-stretch flex-wrap' key={item.text}>
                    <Header>
                      <p className='fs-14 lh-17 fs-md-16 lg-md-19 fs-xl-18 lh-xl-22 text-white mb-0'>{item.title}</p>
                    </Header>
                    <Body>
                      <div className='product'>
                        <div className='card-img'>
                          <ImageWebp
                            pathSrc={item.image}
                            altDescription={item.paragraph}
                            arrayNumbers={[ 210, 154, 219, 264 ]}
                            arrayNumbersHeight={[ 140, 94, 126, 152 ]}
                          />
                        </div>
                        <div className='row d-flex align-items-center mt-4'>
                          <div className='col-6 col-xl-5 d-flex justify-content-center mb-xl-0 mb-3'>
                            <View className='d-flex justify-content-end'>
                              <Circle>
                                {radomViews()}
                              </Circle>
                            </View>
                          </div>
                          <div className='col-6 col-xl-7 pl-xl-0'>
                            <p className='fs-10 lh-15 text-black'>{item.text}</p>
                          </div>
                        </div>
                        <Button
                          href={item.link}
                          className='fw-700'
                          onClick={() => {
                            sendDatalayerEvent({
                              section: 'dobra_2',
                              section_name: 'Esquenta Semana do Consumidor',
                              element_action: 'click button',
                              element_name: item.name,
                              redirect_url: item.link,
                            })
                          }}
                          target='__blank'
                        >
                          Abrir ofertas
                        </Button>
                      </div>
                    </Body>
                  </Card>
                ))
              }
            </DefaultCarousel>
          </div> :
          <div className='row mt-md-4 justify-content-center'>
            {cardInter.map((item: CardProps) => (
              <div className='col-md-4 col-lg-3 mb-md-5'>
                <Card key={item.text}>
                  <Header>
                    <p className='fs-14 lh-17 fs-md-16 lg-md-19 fs-xl-18 lh-xl-22 text-white mb-0'>{item.title}</p>
                  </Header>
                  <Body className='my-md-2 mt-lg-4'>
                    <ImageWebp
                      pathSrc={item.image}
                      altDescription={item.paragraph}
                      arrayNumbers={[ 210, 60, 219, 264 ]}
                      arrayNumbersHeight={[ 140, 140, 126, 152 ]}
                    />
                    <div className='content'>
                      <div className='row d-flex align-items-center mt-4 px-md-4 px-xl-4'>
                        <div className='col-6 col-xl-5 d-flex justify-content-center mb-xl-0 mb-3 mb-md-0'>
                          <View className='d-flex justify-content-end'>
                            <Circle>
                              {radomViews()}
                            </Circle>
                          </View>
                        </div>
                        <div className='col-6 col-xl-7 pl-xl-0'>
                          <p className='fs-10 lh-12 mb-md-0 text-black'>{item.text}</p>
                        </div>
                      </div>
                      <Button
                        href={item.link}
                        className='mt-md-4 mt-lg-3 fw-700'
                        onClick={() => {
                          sendDatalayerEvent({
                            section: 'dobra_2',
                            section_name: 'Esquenta Semana do Consumidor',
                            element_action: 'click button',
                            element_name: item.name,
                            redirect_url: item.link,
                            })
                        }}
                        target='__blank'
                      >
                        Abrir ofertas
                      </Button>
                    </div>
                  </Body>
                </Card>
              </div>
            ))}
          </div>
          }
        </div>
      </div>
    </Section>
  )
}

export default ConsumerMonthHotDeals
