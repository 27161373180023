import React, { useState, useEffect, useLayoutEffect } from 'react'
import { alphaCode } from 'src/utils/alpha-code-utms'
import { WIDTH_MD } from 'src/utils/breakpoints'

// Hooks
import useDomReady from 'src/hooks/window/useDomReady'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import useWidth from 'src/hooks/window/useWidth'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'

import { getParameterByName } from 'src/shared/helpers'

// Components
import ModalAccount from 'src/components/UI/Forms/OpenAccountRightForm'
import { Modal } from 'src/components/Modal'

const ConsumerDay = () => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const [ utmSource, setUtmSource ] = useState<string>('9io9bkj')
  const [ utmCampaign, setUtmCampaign ] = useState<string>('site_contadigital_aquisicao')
  const [ isMobile, setIsMobile ] = useState(false)
  const domReady = useDomReady()
  const [ open, setOpen ] = useState(false)
  const width = useWidth()

  const url = `https://bancointer.go.link?adj_t=${alphaCode(utmSource)}&adj_campaign=${utmCampaign}&adj_adgroup=contadigital&adj_creative=lp-cdpf-mobile&adj_label=aquisicao&adj_fallback=https%3A%2F%2Fwww.bancointer.com`

  const [ dataLayer, setDataLayer ] = useState<IDataLayerParams>({
    section: 'dobra_01',
    section_name: 'Conta Digital. Gratuita, simples e completa',
    element_name: 'Abra sua conta',
    element_action: 'click button',
    redirect_url: url,
  })
  useEffect(() => {
    setUtmSource(getParameterByName('utm_source', '') as string || '9io9bkj')
    setUtmCampaign(getParameterByName('utm_campaign', '') as string || 'site_contadigital_aquisicao')
  }, [])

  useLayoutEffect(() => {
    setIsMobile(width < WIDTH_MD)
  }, [ width ])

  const openModal = domReady && (
    <Modal isModalOpen={open} setIsModalOpen={setOpen} locationToRender={document.body}>
      <ModalAccount
        closeModal={() => setOpen(false)} dataLayer={dataLayer}
      />
    </Modal>
  )

  return (
    <section className='py-5'>
      {openModal}
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <h2
              className='fs-24 fs-md-32 fs-lg-40 lh-28 f lh-md-40 lh-lg-48 text-grayscale--500 fw-600 mb-5 text-center'
            >
              Tudo sobre o Dia do Consumidor 2024
            </h2>
          </div>
        </div>
        <div className='row'>
          <div className='col-12 col-md-6'>
            <h3 className='fs-14 lh-16 text-grayscale--500 fw-700'>O que é o Dia do Consumidor?</h3>
            <p className='fs-14 lh-16 text-grayscale--500'><span className='fw-700'>O Dia do Consumidor é comemorado em 15 de março.</span> A data ficou marcada a partir de um pronunciamento feito em 1962 pelo ex-presidente dos EUA, John Kennedy, ao Congresso defendendo os <span className='fw-700'>direitos do consumidor.</span></p>
            <p className='fs-14 lh-16 text-grayscale--500'>No Brasil, o Dia do Consumidor se consolidou a partir de 2014, quando grandes marcas incorporaram a data no calendário comercial oferecendo descontos.</p>
          </div>
          <div className='col-12 col-md-6 mt-3 mt-md-0'>
            <h3 className='fs-14 lh-16 text-grayscale--500 fw-700'>Quando é a Semana do Consumidor 2024?</h3>
            <p className='fs-14 lh-16 text-grayscale--500'><span className='fw-700'>Março é o Mês do Consumidor e a Semana do Consumidor 2024 acontecerá do dia 11/3 ao 17/03.</span> E no Shopping do Inter, você vai aproveitar as melhores promoções do mercado em um único lugar.</p>
            <p className='fs-14 lh-16 text-grayscale--500'>
              Mas fique ligado, porque algumas ofertas serão antecipadas e exclusivas. Se você ainda não é cliente Inter, 
              <span
                className='text-orange--extra cursor-pointer'
                onClick={() => {
                  setOpen(true)
                  setDataLayer(dataLayer)
                  sendDatalayerEvent({
                    section: 'dobra_01',
                    element_action: 'click button',
                    section_name: 'Tudo sobre o Dia do Consumidor 2024',
                    element_name: 'Abrir conta gratuita',
                  })
                }}  
              > abra agora sua conta gratuita e aproveite o Super App
              </span>!
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ConsumerDay
