export default [
  {
    title: 'Celular',
    image: 'https://central-imagens.bancointer.com.br/images-without-small-versions/celular-consumidor/image.webp',
    text: 'pessoas vendo promoção de Celular',
    link: 'https://intergo.app/523cb5e6',
    paragraph: 'Celular em promoção',
    name: 'Abrir oferta - celular',
  },
  {
    title: 'Informática',
    image: 'https://central-imagens.bancointer.com.br/images-without-small-versions/notebook-consumidor/image.webp',
    text: 'pessoas vendo promoção de Notebook',
    link: 'https://intergo.app/4d9fa3ec',
    paragraph: 'Notebook em promoção',
    name: 'Abrir oferta-notebook',
  },
  {
    title: 'Games',
    image: 'https://central-imagens.bancointer.com.br/images-without-small-versions/game-consumidor/image.webp',
    text: 'pessoas vendo promoção de Playstation',
    link: 'https://intergo.app/4029c778',
    paragraph: 'Playstation em promoção',
    name: 'Abrir oferta-games',
  },
  {
    title: 'Esporte',
    image: 'https://central-imagens.bancointer.com.br/images-without-small-versions/tenis-consumidor/image.webp',
    text: 'pessoas vendo promoção de Tênis',
    link: 'https://intergo.app/3a7d32ca',
    paragraph: 'Tênis em promoção',
    name: 'Abrir oferta-esporte',
  },
  {
    title: 'Beleza e perfumaria',
    image: 'https://central-imagens.bancointer.com.br/images-without-small-versions/perfume-consumidor/image.webp',
    text: 'pessoas vendo promoção de Perfume',
    link: 'https://intergo.app/db2af17f',
    paragraph: 'Perfume em promoção',
    name: 'Abrir oferta-modaebeleza',
  },
  {
    title: 'TV',
    image: 'https://central-imagens.bancointer.com.br/images-without-small-versions/smart-tv-consumidor/image.webp',
    text: 'pessoas vendo promoção de Smart TV',
    link: 'https://intergo.app/897b5575',
    paragraph: 'TV em promoção',
    name: 'Abrir oferta-tv',
  },
  {
    title: 'Eletroportáteis ',
    image: 'https://central-imagens.bancointer.com.br/images-without-small-versions/fritadeira-consumidor/image.webp',
    text: 'pessoas vendo promoção de Air Fryer',
    link: 'https://intergo.app/3a29fd94',
    paragraph: 'Air Fryer em promoção',
    name: 'Abrir oferta-eletroportáteis',
  },
  {
    title: 'Eletrodomésticos',
    image: 'https://central-imagens.bancointer.com.br/images-without-small-versions/lavadora-consumidor/image.webp',
    text: 'pessoas vendo promoção de Lava e Seca',
    link: 'https://intergo.app/1f52299d',
    paragraph: 'Lava e Seca em promoção',
    name: 'Abrir oferta-eletrodomesticos',
  },
  {
    title: 'Passagens aéreas',
    image: 'https://central-imagens.bancointer.com.br/images-without-small-versions/consumidor-viagem/image.webp',
    text: 'pessoas vendo promoção de passagem',
    link: 'https://intergo.app/585a7b19',
    paragraph: 'Passagens aéreas em promoção',
    name: 'Abrir oferta-passagens-aéreas',
  },
  {
    title: 'Delivery',
    image: 'https://central-imagens.bancointer.com.br/images-without-small-versions/1440-dobra2-ifood/image.webp',
    text: 'pessoas vendo promoção de Delivery',
    link: 'https://intergo.app/170b34c6',
    paragraph: 'Passagens aéreas em promoção',
    name: 'Abrir oferta-delivery',
  },
  {
    title: 'Gift Card',
    image: 'https://central-imagens.bancointer.com.br/images-without-small-versions/1440-dobra2-uber/image.webp',
    text: 'pessoas vendo promoção de Gift Card',
    link: 'https://intergo.app/7f88282d',
    paragraph: 'Gift Card em promoção',
    name: 'Abrir oferta-gift-card',
  },
  {
    title: 'Duo Gourmet',
    image: 'https://central-imagens.bancointer.com.br/images-without-small-versions/1440-dobra2-duo/image.webp',
    text: 'pessoas vendo promoção de Duo Gourmet',
    link: 'https://intergo.app/27261daa',
    paragraph: 'Duo Gourme em promoçãot',
    name: 'Abrir oferta-gift-card',
  }
]
