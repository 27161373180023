import React from 'react'

import { Modal, CloseButton, Button, Link } from './style'

import Success from '../../assets/image/success.png'
import Error from '../../assets/image/error.png'
import Close from '@interco/icons/build-v4/bidis/v2/navigation/close'

const menssagens = {
  error: {
    title: 'Houve um erro por aqui',
    paragraph01: 'Não foi possível criar o seu cadastro. Experimente voltar dentro de alguns instantes.',
  },
  success: {
    title: 'Lista de Desejos cadastrada com sucesso.',
    paragraph01: '',

  },
}

type ModalMensssageProps = {
  type: string;
  setOpen: Function;
}

const ModalMenssage = ({ setOpen, type }: ModalMensssageProps) => {
  const handleClick = () => {
    setOpen(false)
    document.location.reload()
  }

  return (
    <Modal className='px-5'>
      <CloseButton onClick={() => handleClick()}>
        <Close height={24} width={24} color='#FF7A00' />
      </CloseButton>
      <div className='relative'>
        {
          type === 'success' ? <img src={Success} /> : <img src={Error} />
        }
        <h3 className='fs-20 lh-25 text-grayscale--500 fw-600 text-center mt-3 mb-4' dangerouslySetInnerHTML={{ __html: menssagens[type as 'success' | 'error'].title }}></h3>
        <p className='fs-14 lh-17 text-grayscale--500 text-center'>{menssagens[type as 'success' | 'error'].paragraph01}</p>
        {
          type === 'success' ? 
          <Link className='d-flex justify-content-center align-items-center fw-700' target='blank' href='https://intergo.app/9059f2ed'>Conferir ofertas do dia</Link> :
          <Button onClick={() => handleClick()}>Entendi</Button>
        }
      </div>
    </Modal>
  )
}

export default ModalMenssage
