import React, { useState, useLayoutEffect, useEffect } from 'react'
import axios from 'axios'

import { WIDTH_MD } from 'src/utils/breakpoints'
import { useForm, UseFormMethods } from 'react-hook-form'
import { HandleClickLinkButton } from 'src/hooks/useDataLayer/utilsUseDataLayer'
import * as URLs from 'src/config/api/Urls'
import { sendCDPFormData } from 'src/shared/helpers'

// Hooks
import useDomReady from 'src/hooks/window/useDomReady'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import useWidth from 'src/hooks/window/useWidth'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'

// Components
import ModalAccount from 'src/components/UI/Forms/OpenAccountRightForm'
import { Modal } from 'src/components/Modal'
import ModalMenssage from '../../_components/_modal/_index'
import { getParameterByName } from 'src/shared/helpers'

import { Section, Button, Card, ButtonForm, Select, Check  } from './style'
import ChevronDown from '@interco/icons/build-v4/orangeds/LG/chevron-down'
import selectJSON from '../../assets/data/select.json'

// Assets
import AteMobile from '../../assets/image/ate-mobile.png'
import Ate from '../../assets/image/ate.png'
import Inter from '../../assets/image/inter.png'
import { SuperAppInterProps } from '../../types'

import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import AcceptTerms from 'src/components/AcceptTerms/SemanaDoConsumidor'

type Category = {
  text: string;
  slug: string;
  select: boolean;
}

interface IFormValues {
  nome: string;
  email: string;
  CpfCnpj: string;
  conteudo01: string;
}

const Hero = ({ setOpenModal }: SuperAppInterProps) => {
  const { register, errors, handleSubmit, setValue }: UseFormMethods<IFormValues> = useForm<IFormValues>()
  const [ loading, setLoading ] = useState(false)
  const [ categoryList, setCategoryList ] = useState<Category[]>(selectJSON)
  const [ accept, setAccept ] = useState(false)
  const [ sendDatalayerEvent ] = useDataLayer()
  const [ isMobile, setIsMobile ] = useState(false)
  const width = useWidth()
  const domReady = useDomReady()
  const [ open, setOpen ] = useState(false)
  const [ openModalForm, setOpenModalForm ] = useState(false)
  const [ type, setType ] = useState('success')

  // Estados dos campos que serão capturados no App
  const [ nome, setNome ] = useState<string | string[] | null | undefined>(null)
  const [ email, setEmail ] = useState<string | string[] | null | undefined>(null)
  const [ CpfCnpj, setCpfCnpj ] = useState<string | string[] | null | undefined>(null)

  const [ dataLayer, setDataLayer ] = useState<IDataLayerParams>({
    section: 'dobra_01',
    section_name: 'Semana do Consumidor 2024',
    element_name: 'Enviar minha lista',
    element_action: 'click button',
  })

  useLayoutEffect(() => {
    setIsMobile(width < WIDTH_MD)
  }, [ width ])

  // useEffect dos campos que serão capturados no App
  useEffect(() => {
    setNome(getParameterByName('nome', ''))
    setEmail(getParameterByName('email', ''))
    setCpfCnpj(getParameterByName('CpfCnpj', ''))
  }, [])


  const openModal = domReady && (
    <Modal isModalOpen={open} setIsModalOpen={setOpen} locationToRender={document.body}>
      <ModalAccount
        closeModal={() => setOpen(false)} dataLayer={dataLayer}
      />
    </Modal>
  )

  const ModalMenssagem = domReady && (
    <Modal isModalOpen={openModalForm} setIsModalOpen={setOpenModal} locationToRender={document.body}>
      <ModalMenssage type={type} setOpen={setOpenModal} />
    </Modal>
  )

  const formSubmit = async (data: IFormValues) => {
    setLoading(true)

    /*
    Enviando os dados nome, email e CpfCnpj nos forms de LPs WebView usados no App:
    Neste tipo de LP os campos acima não existirão no form, estes dados serão capturados dentro do próprio app.
    Estes campos deverão ser exatamente como estão abaixo, ou seja: nome, email e CpfCnpj.
    Para funcionar deverão ser criados os estados de cada campo e passados no useEffect.
    Exemplo de teste local: http://localhost:8000/semana-do-consumidor-app/?nome=bruno&CpfCnpj=04822244601&email=bruno%40inter.com
    */

    const formData = {
      ...data,
      aceite: accept,
      campanha: 'Semana Consumidor 2024',
      descricao: 'webview',
      nome: nome || '',
      email: email || '',
      CpfCnpj: CpfCnpj || '',
    }

    try {
      await axios.post(`${URLs.CONTACT_FORM_POST_V5}/lp-mktcloud-inter`, [ formData ], {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      HandleClickLinkButton('dobra_01', 'submit', 'Enviar minha lista', '0')
      sendCDPFormData({ formName: 'Semana do consumidor 2024', cpf: data.CpfCnpj, email: data.email, nome: data.nome })
      setType('success')
      setOpenModalForm(true)
      sendDatalayerEvent({
        section: 'dobra_1',
        element_action: 'submit',
        element_name: 'Enviar minha lista',
        section_name: 'Semana do Consumidor 2024',
      })
    } catch (err) {
      setLoading(false)
      setType('error')
      setOpenModalForm(true)
    }
  }

  const handleCheck = (slug: string) => {
    const newList = categoryList.map((item: Category) => {
      if (slug === item.slug) {
        return {
          ...item,
          select: !item.select,
        }
      } else {
        return item
      }
    })
    setCategoryList(newList)
  }

  return (
    <Section
      className='py-5 d-flex justify-content-between'
      id='hero'
    >
      {openModal}
      {ModalMenssagem}
      <div className='container'>
        <div className='row d-flex align-items-center justify-content-between'>
          <div className='col-12 col-md-6 pr-lg-0'>
            <img className='logo-inter mb-3' src={Inter} />
            <h1 className='fs-24 lh-27 fs-lg-32 lh-lg-36 fs-xl-46 lh-xl-54 text-white fw-700 mb-3'>
              Semana do Consumidor 2024
            </h1>
            <p className='fs-24 fs-xl-32 lh-28 lh-xl-35 text-white'>Para bom consumidor, muito cashback basta.</p>
            <div className='d-flex justify-content-center justify-content-md-start mb-3 mt-md-4'>
              {
                width < WIDTH_MD ? <img className='ate' src={AteMobile} /> :
                <img className='ate' src={Ate} />
              }
            </div>
            {width >= WIDTH_MD && <div className='col-12'>
              <a
                href='https://intergo.app/9059f2ed'
                target='blank'
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_01',
                    section_name: 'Semana do Consumidor 2024',
                    element_action: 'click scroll',
                    element_name: 'Aproveitar agora',
                    redirect_url: 'https://intergo.app/9059f2ed',
                  })
                }}
                className='btn btn-white btn--lg fs-14 fw-600 rounded-2 mx-auto text-orange--extra text-none mt-4 link'
              >
                Aproveitar agora
              </a>
            </div>}
          </div>
          <div className='col-12 col-md-6 col-lg-5 offset-lg-1 offset-xl-0 d-flex justify-content-center mt-3 mt-md-0'>
            <Card> 
              <h2
                className='fs-14 lh-17 fs-md-16 lh-md-20 fs-xl-20 lh-xl-25 text-grayscale--500 text-center fw-700'
              >
                Monte sua <span className='text-orange--extra'>Lista de Desejos</span> e garanta ofertas exclusivas 🧡
              </h2>
              <form onSubmit={handleSubmit(formSubmit)}>
                <div className='row'>
                  <div className={`col-12 select-area ${errors.conteudo01 && 'error'}`}>
                    <label className='fs-14 lh-17 text-grayscale--500 mt-3 mb-1'>Lista de desejos</label>
                    <input
                      className='d-none'
                      type='text'
                      value={categoryList.filter((item: Category) => item.select).map((item: Category) => item.slug).join(',')}
                      name='conteudo01'
                      ref={register({
                      required: 'Por favor, digite seu nome completo',
                    })}
                    />
                    <Select>
                      <summary>
                        <p className='fs-14 lh-17 text-grayscale--300 fw-600 mb-0'>Selecione as categorias
                        </p>
                        <ChevronDown height={24} width={24} color='#FF7A00' className='chevron' />
                      </summary>
                      <div className='mt-4'>
                        {
                          categoryList.map((item: Category) => (
                            <Check
                              className='d-flex align-items-center mb-2'
                              key={item.text}
                              type='button'
                              onClick={() => handleCheck(item.slug)}
                            >
                              { item.select
                              ? <OrangeIcon size='MD' color='#ff7a00' icon='checkbox-on' />
                               : <OrangeIcon size='MD' color='#B6B7BB' icon='checkbox-off' />
                              }
                              <p className='fs-14 lh-17 text-grayscale--400 mb-0 mr-3 text-left'>{item.text}</p>
                            </Check>
                          ))
                        }
                      </div>
                    </Select>
                    {errors.conteudo01 && <p className='fs-12  mb-0 text-right'>Selecione uma categoria</p>}
                  </div>
                  <div className='col-12 mt-1'>
                    <AcceptTerms accept={accept} setAccept={setAccept} />
                    <ButtonForm
                      type='submit'
                      disabled={!accept || loading}
                    >
                      Enviar minha lista
                    </ButtonForm>
                  </div>
                </div>
              </form>
            </Card>
          </div>
          {width < WIDTH_MD && <div className='col-12'>
            <a
              href='https://intergo.app/9059f2ed'
              target='blank'
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_01',
                  section_name: 'Semana do Consumidor 2024',
                  element_action: 'click scroll',
                  element_name: 'Aproveitar agora',
                  redirect_url: 'https://intergo.app/9059f2ed',
                })
              }}
              className='btn btn-white btn--lg fs-14 fw-600 rounded-2 mx-auto text-orange--extra text-none mt-4'
            >
              Aproveitar agora
            </a>
          </div>}
        </div>
      </div>
    </Section>
  )
}

export default Hero
