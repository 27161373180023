import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

export const Modal = styled.div`
  width: 100%;
  height: auto;
  max-width: 372px;
  border-radius: 8px;
  background: #fff;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  position: absolute;

  @media ${device.tablet} {
    max-width: 470px;
  }

  img{
    margin: 50px auto 30px;
    display: block ;

  }

  .relative{
    position: relative;
    min-height: 100% ;
    padding: 24px  24px 40px;
  }

  /* a {
    width: 203px;
    height: 48px;
    border-radius: 8px;

    &:focus{
      outline: none;
    }
  } */
`

export const LinkBtn = styled.a`
  width: 203px;
  height: 48px;
  border-radius: 8px;

  &:focus{
    outline: none;
  }
`

export const CloseButton = styled.button`
  width: 40px;
  height: 40px;
  background:  none;
  padding: 0;
  border: none ;
  position: absolute ;
  right: 16px;
  top: 16px;

  &:focus{
    outline: none;
  }
`

export const Button = styled.button`
  background: #ff7a00;
  border: none;
  width: 100%;
  height: 48px;
  border-radius: 8px;
  color: #fff;
  font-size: 14px;
  line-height: 17px;

  &:focus{
    outline: none;
  }
`

export const Link = styled.a`
  background: #ff7a00;
  border: none;
  width: 100%;
  height: 48px;
  border-radius: 8px;
  color: #fff;
  font-size: 14px;
  line-height: 17px;
  display: block;

  &:focus{
    outline: none;
  }
`
